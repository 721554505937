<div class="card manhwa" [id]="'divManhwa' + '' + manhwa.id">
    <div class="card-header">
        <h2>{{manhwa.name}}</h2>
    </div>
    <div class="card-body">
        <div class="container">
            <!--
            <div class="row">
                <div class="col-md-4">
                </div>  
                <div class="col">
                    <label [for]="'finishedManhwa' + '' + manhwa.id">Finished?</label>
                    <input type="checkbox" [name]="'finishedManhwa' + '' + manhwa.id" [id]="'finishedManhwa' + '' + manhwa.id">
                </div>
                <div class="col">
                    <label [for]="'breakManhwa' + '' + manhwa.id">On break?</label>
                    <input type="checkbox" [name]="'breakManhwa' + '' + manhwa.id" [id]="'breakManhwa' + '' + manhwa.id">
                </div>
                <div class="col-md-4">
                </div>  
            </div>
            -->
            <div class="row">
                <div class="col">
                    <a href="{{generateLink(manhwa)}}" target="_blank" [id]="'linkManhwa' + '' + manhwa.id">LINK</a>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <p>Current: {{ manhwa.chapter }}</p>
                </div>  
                <div class="col-md-6">
                    <p>Updated: {{ manhwa.lastUpdated }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                </div>  
                <div class="col-md-2">
                    <input class="form-control" type="number" placeholder="Anzahl" [id]="'inCount' + '' + manhwa.id">
                </div>  
                <div class="col-md-2">
                    <button class="btn btn-primary" (click)="changeCount(manhwa, +1)">Increase</button>
                </div>  
                <div class="col-md-2">
                    <button class="btn btn-secondary" (click)="changeCount(manhwa, -1)">Decrease</button>
                </div>
                <div class="col-md-3">
                </div>  
            </div>
        </div>
    </div>
</div>