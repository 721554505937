import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  URL = "https://nicolasovic.ch/apiMedia/";

  constructor(
    private http: HttpClient
  ) { }

  public loadManhwas(){
    return this.http.get(this.URL + "manhwa");
  }

  public addManhwa(values){
    return this.http.post(this.URL + "manhwa", values);
  }

  public changeCount(id, link, newCount, key){
    return this.http.put(this.URL + "manhwa/" + id, {url: link, chapter: newCount, key: key.value})
  }

  public updateManhwa(id, isFinished, isOnBreak, key){
    return this.http.put(this.URL + "manhwa/update/" + id, {isFinished: isFinished, isOnBreak: isOnBreak, key: key.value})
  }
}
