<div class="container">
    <div class="row">
        <div class="col-md-5">
            <input class="form-control" type="text" name="txtName" id="txtName" placeholder="Name">
        </div>
        <div class="col-md-4">
            <input class="form-control" type="text" name="txtLink" id="txtLink" placeholder="Link">
        </div>
        <div class="col-md-2">
            <input class="form-control" type="number" name="numChapter" id="numChapter" placeholder="Chapter">
        </div>
        <div class="col-md-1">
            <button (click)="addMedia()" class="btn btn-success">Add</button>
        </div>
    </div>

    <div class="row">
        <div class="col-md-7">
            <input class="form-control" type="text" name="txtSearch" id="txtSearch" (input)="searchMedia()" placeholder="Search...">
        </div>
        <div class="col-md-5">
            <input class="form-control" type="password" name="txtKey" id="txtKey" placeholder="Key">
        </div>
    </div>

    <div class="row stats">
        <span>Total Manhwas: {{ manhwas?.length }} </span>
        <span>Total Chapters: {{ countChapters() }} </span>
    </div>
</div>

<div class="mediaList" *ngIf="!isSearching()">
    <app-manhwa *ngFor="let manhwa of manhwas" [manhwa]="manhwa" (updated)="this.loadManhwas()"></app-manhwa>
</div>


<div class="mediaList" *ngIf="isSearching()">
    <app-manhwa *ngFor="let manhwa of filteredManhwa" [manhwa]="manhwa" (updated)="this.loadManhwas()"></app-manhwa>
</div>