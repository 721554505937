import { Component, OnInit } from '@angular/core';
import { Manhwa } from './manhwa';
import { Media } from './media';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css'],
  providers: [ApiService]
})
export class MediaComponent implements OnInit {

  manhwas?:Array<Manhwa>;
  filteredManhwa:Array<Manhwa> = new Array<Manhwa>();
  chapters:number = 0;
  needsSeason:boolean = false;
  isFiltered:boolean = false;

  constructor(
    private api: ApiService
  ) { }

  ngOnInit(): void {
    this.loadManhwas();
  }

  loadManhwas(){
    this.api.loadManhwas().subscribe(
      data => {
        this.manhwas = data as Array<Manhwa>;
        this.formatTimestamp();
        if(this.isSearching){
          this.searchMedia();
        }
      }
    )
  }

  formatTimestamp(){
    var year;
    var month;
    var day;
    var hour;
    var minute;
    var second;
    var datePart:string = "";
    var timePart:string = "";

    for(var manhwa of this.manhwas){
      datePart = manhwa.lastUpdated.split("T")[0];
      timePart = manhwa.lastUpdated.split("T")[1];
      year = datePart.split("-")[0];
      month = datePart.split("-")[1];
      day = datePart.split("-")[2];

      hour = timePart.split(":")[0];
      minute = timePart.split(":")[1];
      second = timePart.split(":")[2].split(".")[0];

      manhwa.lastUpdated = hour + ":" + minute + ":" + second + " " + day + "." + month + "." + year;
    }
  }

  addMedia(){
    var name = document.getElementById("txtName") as HTMLInputElement;
    var link = document.getElementById("txtLink") as HTMLInputElement;
    var chapter = document.getElementById("numChapter") as HTMLInputElement;
    var key = document.getElementById("txtKey") as HTMLInputElement;

    var values = {name: name.value, chapter: chapter.valueAsNumber, url: link.value, key: key.value, season: 0};

    this.api.addManhwa(values).subscribe(
      data => {
        this.loadManhwas();
      },
      error => {
        console.log(error);
      }
    )
  }

  searchMedia(){
    var div = document.getElementById("txtSearch") as HTMLInputElement;
    this.filteredManhwa = new Array<Manhwa>();
    this.searchManhwas(div);
  }

  searchManhwas(div){
    for(var i = 0; i < this.manhwas?.length; i++){
      if(this.manhwas[i]?.name.toLowerCase().indexOf(div.value.toLowerCase()) != -1){
        this.filteredManhwa.push(this.manhwas[i]);
      }
    }
  }

  isSearching(){
    var div = document.getElementById("txtSearch") as HTMLInputElement;
    return (this.isFiltered || div.value.length > 0);
  }

  countChapters(){
    if(this.chapters == 0){
      this.manhwas?.forEach(manhwa => {
        this.chapters = this.chapters + manhwa.chapter;
      });
    }
    return this.chapters;
  }

  filterMedia(){
    var media = document.getElementById("filterMedia") as HTMLSelectElement;
    if(media.value != "all"){
      this.isFiltered = true;
    }
    else{
      this.isFiltered = false;
    }
  }
}
