import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Manhwa } from '../media/manhwa';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-manhwa',
  standalone: true,
  imports: [],
  templateUrl: './manhwa.component.html',
  styleUrl: './manhwa.component.css',
  providers: [ApiService]
})
export class ManhwaComponent {
  @Input()
  manhwa? :Manhwa

  @Output()
  updated = new EventEmitter<string>();

  constructor(
    private api: ApiService
  ){}

  generateLink(manhwa:Manhwa){
    if(manhwa.link != null ){
      return manhwa.link.replace("xxx", manhwa.chapter + "");
    }
    else{
      return manhwa.link;
    }
  }

  editLink(manhwa:Manhwa){
    var edit = document.getElementById("btnEdit" + manhwa.id) as HTMLButtonElement;
    var link = document.getElementById("linkManhwa" + manhwa.id) as HTMLLinkElement;
    var div = document.getElementById("divManhwa" + manhwa.id) as HTMLDivElement;
    var key = document.getElementById("txtKey") as HTMLInputElement;
    if(edit.textContent.toLowerCase() == "edit"){
      var input = document.createElement("input") as HTMLInputElement;
      input.value = link.href;
      input.id = "inLink" + manhwa.id;
      div.appendChild(input);
      edit.textContent = "Save";
      link.style.visibility = "hidden";
    }
    else{
      var inLink = document.getElementById("inLink" + manhwa.id) as HTMLInputElement;
      link.style.visibility = "";
      edit.textContent = "Edit";
      this.api.changeCount(manhwa.id, inLink.value, manhwa.chapter, key).subscribe(
        data => {
          this.updated.emit('updated');
          div.removeChild(inLink);
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  changeCount(manhwa: Manhwa, change: number){
    var key = document.getElementById("txtKey") as HTMLInputElement;
    var count = document.getElementById("inCount" + manhwa.id) as HTMLInputElement;
    if(count.value.toString().toLowerCase() != "e" && Number.parseInt(count.value) > 0){
      var newCount = manhwa.chapter + (change * Number.parseInt(count.value));
      this.api.changeCount(manhwa.id, manhwa.link, newCount, key).subscribe(
        data => {
          this.updated.emit('updated');
        },
        error => {
          console.log(error);
        }
      );
    }
  }
}
