import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MediaComponent } from './media/media.component';
import { MainComponent } from './main/main.component';
import { FooterComponent } from './footer/footer.component';
import { GamingComponent } from './gaming/gaming.component';
import { AboutComponent } from './about/about.component';
import { WorkComponent } from './work/work.component';
import { ManhwaComponent } from "./manhwa/manhwa.component";

@NgModule({
    declarations: [
        AppComponent,
        ImpressumComponent,
        DatenschutzComponent,
        MediaComponent,
        MainComponent,
        FooterComponent,
        GamingComponent,
        AboutComponent,
        WorkComponent
    ],
    providers: [],
    bootstrap: [AppComponent],
    imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    ManhwaComponent
]
})
export class AppModule { }
